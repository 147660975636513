/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface AccountBalances {
  balance?: number | undefined;
  byAccount: { [key: string]: BalanceContainer };
}

export interface AccountBalances_ByAccountEntry {
  key: string;
  value: BalanceContainer | undefined;
}

export interface BalanceContainer {
  balance?: number | undefined;
}

function createBaseAccountBalances(): AccountBalances {
  return { balance: undefined, byAccount: {} };
}

export const AccountBalances = {
  encode(message: AccountBalances, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.balance !== undefined) {
      writer.uint32(9).double(message.balance);
    }
    Object.entries(message.byAccount).forEach(([key, value]) => {
      AccountBalances_ByAccountEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountBalances {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountBalances();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.balance = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = AccountBalances_ByAccountEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.byAccount[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountBalances {
    return {
      balance: isSet(object.balance) ? Number(object.balance) : undefined,
      byAccount: isObject(object.byAccount)
        ? Object.entries(object.byAccount).reduce<{ [key: string]: BalanceContainer }>((acc, [key, value]) => {
          acc[key] = BalanceContainer.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: AccountBalances): unknown {
    const obj: any = {};
    message.balance !== undefined && (obj.balance = message.balance);
    obj.byAccount = {};
    if (message.byAccount) {
      Object.entries(message.byAccount).forEach(([k, v]) => {
        obj.byAccount[k] = BalanceContainer.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountBalances>, I>>(base?: I): AccountBalances {
    return AccountBalances.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountBalances>, I>>(object: I): AccountBalances {
    const message = createBaseAccountBalances();
    message.balance = object.balance ?? undefined;
    message.byAccount = Object.entries(object.byAccount ?? {}).reduce<{ [key: string]: BalanceContainer }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = BalanceContainer.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseAccountBalances_ByAccountEntry(): AccountBalances_ByAccountEntry {
  return { key: "", value: undefined };
}

export const AccountBalances_ByAccountEntry = {
  encode(message: AccountBalances_ByAccountEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      BalanceContainer.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountBalances_ByAccountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountBalances_ByAccountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = BalanceContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountBalances_ByAccountEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? BalanceContainer.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AccountBalances_ByAccountEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? BalanceContainer.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountBalances_ByAccountEntry>, I>>(base?: I): AccountBalances_ByAccountEntry {
    return AccountBalances_ByAccountEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountBalances_ByAccountEntry>, I>>(
    object: I,
  ): AccountBalances_ByAccountEntry {
    const message = createBaseAccountBalances_ByAccountEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? BalanceContainer.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseBalanceContainer(): BalanceContainer {
  return { balance: undefined };
}

export const BalanceContainer = {
  encode(message: BalanceContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.balance !== undefined) {
      writer.uint32(9).double(message.balance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.balance = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BalanceContainer {
    return { balance: isSet(object.balance) ? Number(object.balance) : undefined };
  },

  toJSON(message: BalanceContainer): unknown {
    const obj: any = {};
    message.balance !== undefined && (obj.balance = message.balance);
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceContainer>, I>>(base?: I): BalanceContainer {
    return BalanceContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceContainer>, I>>(object: I): BalanceContainer {
    const message = createBaseBalanceContainer();
    message.balance = object.balance ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
