/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { PermanentTagsContainer, ReferenceContainer } from "./misc";
import { PaymentsSubscriptionPrice } from "./payments-subscription";

export const protobufPackage = "";

export interface DialerDirectToAgentAgency {
  id: string;
  active?: boolean | undefined;
  name?: string | undefined;
  profileImage?: string | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  subscription?: DirectToAgentSubscription | undefined;
}

export interface DirectToAgentSubscription {
  price?: PaymentsSubscriptionPrice | undefined;
}

export interface DialerDirectToAgentAgencySettings {
  isDirectToAgent: boolean;
}

export interface DialerDirectToAgentAgentSettings {
  isDirectToAgent: boolean;
  agency?: ReferenceContainer | undefined;
}

function createBaseDialerDirectToAgentAgency(): DialerDirectToAgentAgency {
  return {
    id: "",
    active: undefined,
    name: undefined,
    profileImage: undefined,
    permanentTags: undefined,
    subscription: undefined,
  };
}

export const DialerDirectToAgentAgency = {
  encode(message: DialerDirectToAgentAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.active !== undefined) {
      writer.uint32(16).bool(message.active);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(34).string(message.profileImage);
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(42).fork()).ldelim();
    }
    if (message.subscription !== undefined) {
      DirectToAgentSubscription.encode(message.subscription, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDirectToAgentAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDirectToAgentAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.subscription = DirectToAgentSubscription.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDirectToAgentAgency {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      subscription: isSet(object.subscription) ? DirectToAgentSubscription.fromJSON(object.subscription) : undefined,
    };
  },

  toJSON(message: DialerDirectToAgentAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.active !== undefined && (obj.active = message.active);
    message.name !== undefined && (obj.name = message.name);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.subscription !== undefined &&
      (obj.subscription = message.subscription ? DirectToAgentSubscription.toJSON(message.subscription) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDirectToAgentAgency>, I>>(base?: I): DialerDirectToAgentAgency {
    return DialerDirectToAgentAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDirectToAgentAgency>, I>>(object: I): DialerDirectToAgentAgency {
    const message = createBaseDialerDirectToAgentAgency();
    message.id = object.id ?? "";
    message.active = object.active ?? undefined;
    message.name = object.name ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.subscription = (object.subscription !== undefined && object.subscription !== null)
      ? DirectToAgentSubscription.fromPartial(object.subscription)
      : undefined;
    return message;
  },
};

function createBaseDirectToAgentSubscription(): DirectToAgentSubscription {
  return { price: undefined };
}

export const DirectToAgentSubscription = {
  encode(message: DirectToAgentSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price !== undefined) {
      PaymentsSubscriptionPrice.encode(message.price, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DirectToAgentSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDirectToAgentSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.price = PaymentsSubscriptionPrice.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DirectToAgentSubscription {
    return { price: isSet(object.price) ? PaymentsSubscriptionPrice.fromJSON(object.price) : undefined };
  },

  toJSON(message: DirectToAgentSubscription): unknown {
    const obj: any = {};
    message.price !== undefined &&
      (obj.price = message.price ? PaymentsSubscriptionPrice.toJSON(message.price) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DirectToAgentSubscription>, I>>(base?: I): DirectToAgentSubscription {
    return DirectToAgentSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DirectToAgentSubscription>, I>>(object: I): DirectToAgentSubscription {
    const message = createBaseDirectToAgentSubscription();
    message.price = (object.price !== undefined && object.price !== null)
      ? PaymentsSubscriptionPrice.fromPartial(object.price)
      : undefined;
    return message;
  },
};

function createBaseDialerDirectToAgentAgencySettings(): DialerDirectToAgentAgencySettings {
  return { isDirectToAgent: false };
}

export const DialerDirectToAgentAgencySettings = {
  encode(message: DialerDirectToAgentAgencySettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isDirectToAgent === true) {
      writer.uint32(8).bool(message.isDirectToAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDirectToAgentAgencySettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDirectToAgentAgencySettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isDirectToAgent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDirectToAgentAgencySettings {
    return { isDirectToAgent: isSet(object.isDirectToAgent) ? Boolean(object.isDirectToAgent) : false };
  },

  toJSON(message: DialerDirectToAgentAgencySettings): unknown {
    const obj: any = {};
    message.isDirectToAgent !== undefined && (obj.isDirectToAgent = message.isDirectToAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDirectToAgentAgencySettings>, I>>(
    base?: I,
  ): DialerDirectToAgentAgencySettings {
    return DialerDirectToAgentAgencySettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDirectToAgentAgencySettings>, I>>(
    object: I,
  ): DialerDirectToAgentAgencySettings {
    const message = createBaseDialerDirectToAgentAgencySettings();
    message.isDirectToAgent = object.isDirectToAgent ?? false;
    return message;
  },
};

function createBaseDialerDirectToAgentAgentSettings(): DialerDirectToAgentAgentSettings {
  return { isDirectToAgent: false, agency: undefined };
}

export const DialerDirectToAgentAgentSettings = {
  encode(message: DialerDirectToAgentAgentSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isDirectToAgent === true) {
      writer.uint32(8).bool(message.isDirectToAgent);
    }
    if (message.agency !== undefined) {
      ReferenceContainer.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDirectToAgentAgentSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDirectToAgentAgentSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isDirectToAgent = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = ReferenceContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDirectToAgentAgentSettings {
    return {
      isDirectToAgent: isSet(object.isDirectToAgent) ? Boolean(object.isDirectToAgent) : false,
      agency: isSet(object.agency) ? ReferenceContainer.fromJSON(object.agency) : undefined,
    };
  },

  toJSON(message: DialerDirectToAgentAgentSettings): unknown {
    const obj: any = {};
    message.isDirectToAgent !== undefined && (obj.isDirectToAgent = message.isDirectToAgent);
    message.agency !== undefined &&
      (obj.agency = message.agency ? ReferenceContainer.toJSON(message.agency) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDirectToAgentAgentSettings>, I>>(
    base?: I,
  ): DialerDirectToAgentAgentSettings {
    return DialerDirectToAgentAgentSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDirectToAgentAgentSettings>, I>>(
    object: I,
  ): DialerDirectToAgentAgentSettings {
    const message = createBaseDialerDirectToAgentAgentSettings();
    message.isDirectToAgent = object.isDirectToAgent ?? false;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? ReferenceContainer.fromPartial(object.agency)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
