/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

export enum RuleType {
  RLT_UNKNOWN = 0,
  RLT_TO_ZERO = 1,
  RLT_RULE_BUILDER = 3,
  RLT_ZAPIER = 4,
  RLT_DYNAMIC_CALL_CAP = 5,
  UNRECOGNIZED = -1,
}

export function ruleTypeFromJSON(object: any): RuleType {
  switch (object) {
    case 0:
    case "RLT_UNKNOWN":
      return RuleType.RLT_UNKNOWN;
    case 1:
    case "RLT_TO_ZERO":
      return RuleType.RLT_TO_ZERO;
    case 3:
    case "RLT_RULE_BUILDER":
      return RuleType.RLT_RULE_BUILDER;
    case 4:
    case "RLT_ZAPIER":
      return RuleType.RLT_ZAPIER;
    case 5:
    case "RLT_DYNAMIC_CALL_CAP":
      return RuleType.RLT_DYNAMIC_CALL_CAP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RuleType.UNRECOGNIZED;
  }
}

export function ruleTypeToJSON(object: RuleType): string {
  switch (object) {
    case RuleType.RLT_UNKNOWN:
      return "RLT_UNKNOWN";
    case RuleType.RLT_TO_ZERO:
      return "RLT_TO_ZERO";
    case RuleType.RLT_RULE_BUILDER:
      return "RLT_RULE_BUILDER";
    case RuleType.RLT_ZAPIER:
      return "RLT_ZAPIER";
    case RuleType.RLT_DYNAMIC_CALL_CAP:
      return "RLT_DYNAMIC_CALL_CAP";
    case RuleType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RuleContainer {
  type?: RuleType | undefined;
  builder?: { [key: string]: any } | undefined;
  takeNegativeBalance?: boolean | undefined;
}

export interface RuleProcessingInput {
  payoutTotal: number;
  accountBalance: number;
}

export interface RuleProcessingRequest {
  rule: RuleContainer | undefined;
  input: RuleProcessingInput | undefined;
}

export interface RuleProcessingResult {
  accountBalanceChange: number;
}

function createBaseRuleContainer(): RuleContainer {
  return { type: undefined, builder: undefined, takeNegativeBalance: undefined };
}

export const RuleContainer = {
  encode(message: RuleContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.builder !== undefined) {
      Struct.encode(Struct.wrap(message.builder), writer.uint32(18).fork()).ldelim();
    }
    if (message.takeNegativeBalance !== undefined) {
      writer.uint32(24).bool(message.takeNegativeBalance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.builder = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.takeNegativeBalance = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleContainer {
    return {
      type: isSet(object.type) ? ruleTypeFromJSON(object.type) : undefined,
      builder: isObject(object.builder) ? object.builder : undefined,
      takeNegativeBalance: isSet(object.takeNegativeBalance) ? Boolean(object.takeNegativeBalance) : undefined,
    };
  },

  toJSON(message: RuleContainer): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type !== undefined ? ruleTypeToJSON(message.type) : undefined);
    message.builder !== undefined && (obj.builder = message.builder);
    message.takeNegativeBalance !== undefined && (obj.takeNegativeBalance = message.takeNegativeBalance);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleContainer>, I>>(base?: I): RuleContainer {
    return RuleContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleContainer>, I>>(object: I): RuleContainer {
    const message = createBaseRuleContainer();
    message.type = object.type ?? undefined;
    message.builder = object.builder ?? undefined;
    message.takeNegativeBalance = object.takeNegativeBalance ?? undefined;
    return message;
  },
};

function createBaseRuleProcessingInput(): RuleProcessingInput {
  return { payoutTotal: 0, accountBalance: 0 };
}

export const RuleProcessingInput = {
  encode(message: RuleProcessingInput, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payoutTotal !== 0) {
      writer.uint32(9).double(message.payoutTotal);
    }
    if (message.accountBalance !== 0) {
      writer.uint32(17).double(message.accountBalance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleProcessingInput {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleProcessingInput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.payoutTotal = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.accountBalance = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleProcessingInput {
    return {
      payoutTotal: isSet(object.payoutTotal) ? Number(object.payoutTotal) : 0,
      accountBalance: isSet(object.accountBalance) ? Number(object.accountBalance) : 0,
    };
  },

  toJSON(message: RuleProcessingInput): unknown {
    const obj: any = {};
    message.payoutTotal !== undefined && (obj.payoutTotal = message.payoutTotal);
    message.accountBalance !== undefined && (obj.accountBalance = message.accountBalance);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleProcessingInput>, I>>(base?: I): RuleProcessingInput {
    return RuleProcessingInput.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleProcessingInput>, I>>(object: I): RuleProcessingInput {
    const message = createBaseRuleProcessingInput();
    message.payoutTotal = object.payoutTotal ?? 0;
    message.accountBalance = object.accountBalance ?? 0;
    return message;
  },
};

function createBaseRuleProcessingRequest(): RuleProcessingRequest {
  return { rule: undefined, input: undefined };
}

export const RuleProcessingRequest = {
  encode(message: RuleProcessingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rule !== undefined) {
      RuleContainer.encode(message.rule, writer.uint32(10).fork()).ldelim();
    }
    if (message.input !== undefined) {
      RuleProcessingInput.encode(message.input, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleProcessingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleProcessingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rule = RuleContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.input = RuleProcessingInput.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleProcessingRequest {
    return {
      rule: isSet(object.rule) ? RuleContainer.fromJSON(object.rule) : undefined,
      input: isSet(object.input) ? RuleProcessingInput.fromJSON(object.input) : undefined,
    };
  },

  toJSON(message: RuleProcessingRequest): unknown {
    const obj: any = {};
    message.rule !== undefined && (obj.rule = message.rule ? RuleContainer.toJSON(message.rule) : undefined);
    message.input !== undefined && (obj.input = message.input ? RuleProcessingInput.toJSON(message.input) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleProcessingRequest>, I>>(base?: I): RuleProcessingRequest {
    return RuleProcessingRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleProcessingRequest>, I>>(object: I): RuleProcessingRequest {
    const message = createBaseRuleProcessingRequest();
    message.rule = (object.rule !== undefined && object.rule !== null)
      ? RuleContainer.fromPartial(object.rule)
      : undefined;
    message.input = (object.input !== undefined && object.input !== null)
      ? RuleProcessingInput.fromPartial(object.input)
      : undefined;
    return message;
  },
};

function createBaseRuleProcessingResult(): RuleProcessingResult {
  return { accountBalanceChange: 0 };
}

export const RuleProcessingResult = {
  encode(message: RuleProcessingResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountBalanceChange !== 0) {
      writer.uint32(9).double(message.accountBalanceChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleProcessingResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleProcessingResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.accountBalanceChange = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleProcessingResult {
    return { accountBalanceChange: isSet(object.accountBalanceChange) ? Number(object.accountBalanceChange) : 0 };
  },

  toJSON(message: RuleProcessingResult): unknown {
    const obj: any = {};
    message.accountBalanceChange !== undefined && (obj.accountBalanceChange = message.accountBalanceChange);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleProcessingResult>, I>>(base?: I): RuleProcessingResult {
    return RuleProcessingResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleProcessingResult>, I>>(object: I): RuleProcessingResult {
    const message = createBaseRuleProcessingResult();
    message.accountBalanceChange = object.accountBalanceChange ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
