/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DialerAgencySettings, DialerCurrentCall } from "./call";
import { DialerDirectToAgentAgencySettings } from "./direct-to-agent";
import { ExecutiveUserContainer } from "./executive";
import { DialerWebRtc, Features, PermanentTagsContainer } from "./misc";
import { UserStripeContainer, UserType, userTypeFromJSON, userTypeToJSON } from "./user-enum";

export const protobufPackage = "";

export interface Agency {
  id: string;
  email?: string | undefined;
  name?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  ssn?: string | undefined;
  profileImage?: string | undefined;
  currentCall?: DialerCurrentCall | undefined;
  webrtc?: DialerWebRtc | undefined;
  features?: Features | undefined;
  executives?: ExecutiveUserContainer | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
  loaParentAgency?: string | undefined;
  dialer?: DialerAgencySettings | undefined;
  directToAgent?: DialerDirectToAgentAgencySettings | undefined;
  userType?: UserType | undefined;
  stripe?: UserStripeContainer | undefined;
}

function createBaseAgency(): Agency {
  return {
    id: "",
    email: undefined,
    name: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    ssn: undefined,
    profileImage: undefined,
    currentCall: undefined,
    webrtc: undefined,
    features: undefined,
    executives: undefined,
    permanentTags: undefined,
    loaParentAgency: undefined,
    dialer: undefined,
    directToAgent: undefined,
    userType: undefined,
    stripe: undefined,
  };
}

export const Agency = {
  encode(message: Agency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.firstName !== undefined) {
      writer.uint32(34).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(42).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(50).string(message.npn);
    }
    if (message.ssn !== undefined) {
      writer.uint32(58).string(message.ssn);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(66).string(message.profileImage);
    }
    if (message.currentCall !== undefined) {
      DialerCurrentCall.encode(message.currentCall, writer.uint32(74).fork()).ldelim();
    }
    if (message.webrtc !== undefined) {
      DialerWebRtc.encode(message.webrtc, writer.uint32(82).fork()).ldelim();
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(90).fork()).ldelim();
    }
    if (message.executives !== undefined) {
      ExecutiveUserContainer.encode(message.executives, writer.uint32(98).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(106).fork()).ldelim();
    }
    if (message.loaParentAgency !== undefined) {
      writer.uint32(114).string(message.loaParentAgency);
    }
    if (message.dialer !== undefined) {
      DialerAgencySettings.encode(message.dialer, writer.uint32(122).fork()).ldelim();
    }
    if (message.directToAgent !== undefined) {
      DialerDirectToAgentAgencySettings.encode(message.directToAgent, writer.uint32(130).fork()).ldelim();
    }
    if (message.userType !== undefined) {
      writer.uint32(136).int32(message.userType);
    }
    if (message.stripe !== undefined) {
      UserStripeContainer.encode(message.stripe, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Agency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentCall = DialerCurrentCall.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.webrtc = DialerWebRtc.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.executives = ExecutiveUserContainer.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.loaParentAgency = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.dialer = DialerAgencySettings.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.directToAgent = DialerDirectToAgentAgencySettings.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.stripe = UserStripeContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Agency {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      email: isSet(object.email) ? String(object.email) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      currentCall: isSet(object.currentCall) ? DialerCurrentCall.fromJSON(object.currentCall) : undefined,
      webrtc: isSet(object.webrtc) ? DialerWebRtc.fromJSON(object.webrtc) : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      executives: isSet(object.executives) ? ExecutiveUserContainer.fromJSON(object.executives) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
      loaParentAgency: isSet(object.loaParentAgency) ? String(object.loaParentAgency) : undefined,
      dialer: isSet(object.dialer) ? DialerAgencySettings.fromJSON(object.dialer) : undefined,
      directToAgent: isSet(object.directToAgent)
        ? DialerDirectToAgentAgencySettings.fromJSON(object.directToAgent)
        : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      stripe: isSet(object.stripe) ? UserStripeContainer.fromJSON(object.stripe) : undefined,
    };
  },

  toJSON(message: Agency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.currentCall !== undefined &&
      (obj.currentCall = message.currentCall ? DialerCurrentCall.toJSON(message.currentCall) : undefined);
    message.webrtc !== undefined && (obj.webrtc = message.webrtc ? DialerWebRtc.toJSON(message.webrtc) : undefined);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.executives !== undefined &&
      (obj.executives = message.executives ? ExecutiveUserContainer.toJSON(message.executives) : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    message.loaParentAgency !== undefined && (obj.loaParentAgency = message.loaParentAgency);
    message.dialer !== undefined &&
      (obj.dialer = message.dialer ? DialerAgencySettings.toJSON(message.dialer) : undefined);
    message.directToAgent !== undefined && (obj.directToAgent = message.directToAgent
      ? DialerDirectToAgentAgencySettings.toJSON(message.directToAgent)
      : undefined);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.stripe !== undefined &&
      (obj.stripe = message.stripe ? UserStripeContainer.toJSON(message.stripe) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Agency>, I>>(base?: I): Agency {
    return Agency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Agency>, I>>(object: I): Agency {
    const message = createBaseAgency();
    message.id = object.id ?? "";
    message.email = object.email ?? undefined;
    message.name = object.name ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.ssn = object.ssn ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.currentCall = (object.currentCall !== undefined && object.currentCall !== null)
      ? DialerCurrentCall.fromPartial(object.currentCall)
      : undefined;
    message.webrtc = (object.webrtc !== undefined && object.webrtc !== null)
      ? DialerWebRtc.fromPartial(object.webrtc)
      : undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.executives = (object.executives !== undefined && object.executives !== null)
      ? ExecutiveUserContainer.fromPartial(object.executives)
      : undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    message.loaParentAgency = object.loaParentAgency ?? undefined;
    message.dialer = (object.dialer !== undefined && object.dialer !== null)
      ? DialerAgencySettings.fromPartial(object.dialer)
      : undefined;
    message.directToAgent = (object.directToAgent !== undefined && object.directToAgent !== null)
      ? DialerDirectToAgentAgencySettings.fromPartial(object.directToAgent)
      : undefined;
    message.userType = object.userType ?? undefined;
    message.stripe = (object.stripe !== undefined && object.stripe !== null)
      ? UserStripeContainer.fromPartial(object.stripe)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
