/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserIdContainer } from "./misc";

export const protobufPackage = "";

export enum PaymentsSubscriptionType {
  PST_NONE = 0,
  PST_PLATFORM_FEE = 1,
  PST_DIRECT_TO_AGENT = 2,
  UNRECOGNIZED = -1,
}

export function paymentsSubscriptionTypeFromJSON(object: any): PaymentsSubscriptionType {
  switch (object) {
    case 0:
    case "PST_NONE":
      return PaymentsSubscriptionType.PST_NONE;
    case 1:
    case "PST_PLATFORM_FEE":
      return PaymentsSubscriptionType.PST_PLATFORM_FEE;
    case 2:
    case "PST_DIRECT_TO_AGENT":
      return PaymentsSubscriptionType.PST_DIRECT_TO_AGENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentsSubscriptionType.UNRECOGNIZED;
  }
}

export function paymentsSubscriptionTypeToJSON(object: PaymentsSubscriptionType): string {
  switch (object) {
    case PaymentsSubscriptionType.PST_NONE:
      return "PST_NONE";
    case PaymentsSubscriptionType.PST_PLATFORM_FEE:
      return "PST_PLATFORM_FEE";
    case PaymentsSubscriptionType.PST_DIRECT_TO_AGENT:
      return "PST_DIRECT_TO_AGENT";
    case PaymentsSubscriptionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PaymentsSubscriptionStatus {
  PSS_NONE = 0,
  PSS_ACTIVE = 1,
  PSS_INACTIVE = 2,
  UNRECOGNIZED = -1,
}

export function paymentsSubscriptionStatusFromJSON(object: any): PaymentsSubscriptionStatus {
  switch (object) {
    case 0:
    case "PSS_NONE":
      return PaymentsSubscriptionStatus.PSS_NONE;
    case 1:
    case "PSS_ACTIVE":
      return PaymentsSubscriptionStatus.PSS_ACTIVE;
    case 2:
    case "PSS_INACTIVE":
      return PaymentsSubscriptionStatus.PSS_INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentsSubscriptionStatus.UNRECOGNIZED;
  }
}

export function paymentsSubscriptionStatusToJSON(object: PaymentsSubscriptionStatus): string {
  switch (object) {
    case PaymentsSubscriptionStatus.PSS_NONE:
      return "PSS_NONE";
    case PaymentsSubscriptionStatus.PSS_ACTIVE:
      return "PSS_ACTIVE";
    case PaymentsSubscriptionStatus.PSS_INACTIVE:
      return "PSS_INACTIVE";
    case PaymentsSubscriptionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PaymentsSubscription {
  id?: string | undefined;
  name?: string | undefined;
  type?: PaymentsSubscriptionType | undefined;
  agency?: UserIdContainer | undefined;
  price?: PaymentsSubscriptionPrice | undefined;
  status?: PaymentsSubscriptionStatus | undefined;
  stripe?: PaymentsSubscriptionStripe | undefined;
}

export interface PaymentsSubscriptionPrice {
  monthly?: number | undefined;
  initial?: number | undefined;
}

export interface PaymentsSubscriptionStripe {
  productId?: string | undefined;
  monthlyPriceId?: string | undefined;
  initialPriceId?: string | undefined;
  setupUrl?: string | undefined;
}

function createBasePaymentsSubscription(): PaymentsSubscription {
  return {
    id: undefined,
    name: undefined,
    type: undefined,
    agency: undefined,
    price: undefined,
    status: undefined,
    stripe: undefined,
  };
}

export const PaymentsSubscription = {
  encode(message: PaymentsSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(34).fork()).ldelim();
    }
    if (message.price !== undefined) {
      PaymentsSubscriptionPrice.encode(message.price, writer.uint32(42).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(48).int32(message.status);
    }
    if (message.stripe !== undefined) {
      PaymentsSubscriptionStripe.encode(message.stripe, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentsSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentsSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.price = PaymentsSubscriptionPrice.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stripe = PaymentsSubscriptionStripe.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentsSubscription {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? paymentsSubscriptionTypeFromJSON(object.type) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      price: isSet(object.price) ? PaymentsSubscriptionPrice.fromJSON(object.price) : undefined,
      status: isSet(object.status) ? paymentsSubscriptionStatusFromJSON(object.status) : undefined,
      stripe: isSet(object.stripe) ? PaymentsSubscriptionStripe.fromJSON(object.stripe) : undefined,
    };
  },

  toJSON(message: PaymentsSubscription): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? paymentsSubscriptionTypeToJSON(message.type) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.price !== undefined &&
      (obj.price = message.price ? PaymentsSubscriptionPrice.toJSON(message.price) : undefined);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? paymentsSubscriptionStatusToJSON(message.status) : undefined);
    message.stripe !== undefined &&
      (obj.stripe = message.stripe ? PaymentsSubscriptionStripe.toJSON(message.stripe) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentsSubscription>, I>>(base?: I): PaymentsSubscription {
    return PaymentsSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentsSubscription>, I>>(object: I): PaymentsSubscription {
    const message = createBasePaymentsSubscription();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.price = (object.price !== undefined && object.price !== null)
      ? PaymentsSubscriptionPrice.fromPartial(object.price)
      : undefined;
    message.status = object.status ?? undefined;
    message.stripe = (object.stripe !== undefined && object.stripe !== null)
      ? PaymentsSubscriptionStripe.fromPartial(object.stripe)
      : undefined;
    return message;
  },
};

function createBasePaymentsSubscriptionPrice(): PaymentsSubscriptionPrice {
  return { monthly: undefined, initial: undefined };
}

export const PaymentsSubscriptionPrice = {
  encode(message: PaymentsSubscriptionPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.monthly !== undefined) {
      writer.uint32(9).double(message.monthly);
    }
    if (message.initial !== undefined) {
      writer.uint32(17).double(message.initial);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentsSubscriptionPrice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentsSubscriptionPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.monthly = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.initial = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentsSubscriptionPrice {
    return {
      monthly: isSet(object.monthly) ? Number(object.monthly) : undefined,
      initial: isSet(object.initial) ? Number(object.initial) : undefined,
    };
  },

  toJSON(message: PaymentsSubscriptionPrice): unknown {
    const obj: any = {};
    message.monthly !== undefined && (obj.monthly = message.monthly);
    message.initial !== undefined && (obj.initial = message.initial);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentsSubscriptionPrice>, I>>(base?: I): PaymentsSubscriptionPrice {
    return PaymentsSubscriptionPrice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentsSubscriptionPrice>, I>>(object: I): PaymentsSubscriptionPrice {
    const message = createBasePaymentsSubscriptionPrice();
    message.monthly = object.monthly ?? undefined;
    message.initial = object.initial ?? undefined;
    return message;
  },
};

function createBasePaymentsSubscriptionStripe(): PaymentsSubscriptionStripe {
  return { productId: undefined, monthlyPriceId: undefined, initialPriceId: undefined, setupUrl: undefined };
}

export const PaymentsSubscriptionStripe = {
  encode(message: PaymentsSubscriptionStripe, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== undefined) {
      writer.uint32(10).string(message.productId);
    }
    if (message.monthlyPriceId !== undefined) {
      writer.uint32(18).string(message.monthlyPriceId);
    }
    if (message.initialPriceId !== undefined) {
      writer.uint32(26).string(message.initialPriceId);
    }
    if (message.setupUrl !== undefined) {
      writer.uint32(34).string(message.setupUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentsSubscriptionStripe {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentsSubscriptionStripe();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.monthlyPriceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.initialPriceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.setupUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentsSubscriptionStripe {
    return {
      productId: isSet(object.productId) ? String(object.productId) : undefined,
      monthlyPriceId: isSet(object.monthlyPriceId) ? String(object.monthlyPriceId) : undefined,
      initialPriceId: isSet(object.initialPriceId) ? String(object.initialPriceId) : undefined,
      setupUrl: isSet(object.setupUrl) ? String(object.setupUrl) : undefined,
    };
  },

  toJSON(message: PaymentsSubscriptionStripe): unknown {
    const obj: any = {};
    message.productId !== undefined && (obj.productId = message.productId);
    message.monthlyPriceId !== undefined && (obj.monthlyPriceId = message.monthlyPriceId);
    message.initialPriceId !== undefined && (obj.initialPriceId = message.initialPriceId);
    message.setupUrl !== undefined && (obj.setupUrl = message.setupUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentsSubscriptionStripe>, I>>(base?: I): PaymentsSubscriptionStripe {
    return PaymentsSubscriptionStripe.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentsSubscriptionStripe>, I>>(object: I): PaymentsSubscriptionStripe {
    const message = createBasePaymentsSubscriptionStripe();
    message.productId = object.productId ?? undefined;
    message.monthlyPriceId = object.monthlyPriceId ?? undefined;
    message.initialPriceId = object.initialPriceId ?? undefined;
    message.setupUrl = object.setupUrl ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
