/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserStripeContainer } from "./user-enum";

export const protobufPackage = "";

export interface LeadSeller {
  id: string;
  email?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  ssn?: string | undefined;
  name?: string | undefined;
  profileImage?: string | undefined;
  stripe?: UserStripeContainer | undefined;
}

function createBaseLeadSeller(): LeadSeller {
  return {
    id: "",
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    ssn: undefined,
    name: undefined,
    profileImage: undefined,
    stripe: undefined,
  };
}

export const LeadSeller = {
  encode(message: LeadSeller, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(42).string(message.npn);
    }
    if (message.ssn !== undefined) {
      writer.uint32(50).string(message.ssn);
    }
    if (message.name !== undefined) {
      writer.uint32(58).string(message.name);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(66).string(message.profileImage);
    }
    if (message.stripe !== undefined) {
      UserStripeContainer.encode(message.stripe, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadSeller {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadSeller();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.stripe = UserStripeContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadSeller {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      email: isSet(object.email) ? String(object.email) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      stripe: isSet(object.stripe) ? UserStripeContainer.fromJSON(object.stripe) : undefined,
    };
  },

  toJSON(message: LeadSeller): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.email !== undefined && (obj.email = message.email);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.name !== undefined && (obj.name = message.name);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.stripe !== undefined &&
      (obj.stripe = message.stripe ? UserStripeContainer.toJSON(message.stripe) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadSeller>, I>>(base?: I): LeadSeller {
    return LeadSeller.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadSeller>, I>>(object: I): LeadSeller {
    const message = createBaseLeadSeller();
    message.id = object.id ?? "";
    message.email = object.email ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.ssn = object.ssn ?? undefined;
    message.name = object.name ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.stripe = (object.stripe !== undefined && object.stripe !== null)
      ? UserStripeContainer.fromPartial(object.stripe)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
