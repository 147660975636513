/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Any } from "./google/protobuf/any";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

export enum QuestionnaireType {
  GENERIC = 0,
  ENROLLMENT = 1,
  UNRECOGNIZED = -1,
}

export function questionnaireTypeFromJSON(object: any): QuestionnaireType {
  switch (object) {
    case 0:
    case "GENERIC":
      return QuestionnaireType.GENERIC;
    case 1:
    case "ENROLLMENT":
      return QuestionnaireType.ENROLLMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuestionnaireType.UNRECOGNIZED;
  }
}

export function questionnaireTypeToJSON(object: QuestionnaireType): string {
  switch (object) {
    case QuestionnaireType.GENERIC:
      return "GENERIC";
    case QuestionnaireType.ENROLLMENT:
      return "ENROLLMENT";
    case QuestionnaireType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QuestionType {
  CHECKBOX = 0,
  RADIO = 1,
  TEXT = 2,
  NUMBER = 3,
  PHONE_NUMBER = 4,
  ADDRESS = 5,
  DATE = 6,
  MEDICARE_NUMBER = 7,
  PHOTO = 8,
  NPN = 9,
  WEBHOOK = 10,
  OPEN_LINK = 11,
  UNRECOGNIZED = -1,
}

export function questionTypeFromJSON(object: any): QuestionType {
  switch (object) {
    case 0:
    case "CHECKBOX":
      return QuestionType.CHECKBOX;
    case 1:
    case "RADIO":
      return QuestionType.RADIO;
    case 2:
    case "TEXT":
      return QuestionType.TEXT;
    case 3:
    case "NUMBER":
      return QuestionType.NUMBER;
    case 4:
    case "PHONE_NUMBER":
      return QuestionType.PHONE_NUMBER;
    case 5:
    case "ADDRESS":
      return QuestionType.ADDRESS;
    case 6:
    case "DATE":
      return QuestionType.DATE;
    case 7:
    case "MEDICARE_NUMBER":
      return QuestionType.MEDICARE_NUMBER;
    case 8:
    case "PHOTO":
      return QuestionType.PHOTO;
    case 9:
    case "NPN":
      return QuestionType.NPN;
    case 10:
    case "WEBHOOK":
      return QuestionType.WEBHOOK;
    case 11:
    case "OPEN_LINK":
      return QuestionType.OPEN_LINK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuestionType.UNRECOGNIZED;
  }
}

export function questionTypeToJSON(object: QuestionType): string {
  switch (object) {
    case QuestionType.CHECKBOX:
      return "CHECKBOX";
    case QuestionType.RADIO:
      return "RADIO";
    case QuestionType.TEXT:
      return "TEXT";
    case QuestionType.NUMBER:
      return "NUMBER";
    case QuestionType.PHONE_NUMBER:
      return "PHONE_NUMBER";
    case QuestionType.ADDRESS:
      return "ADDRESS";
    case QuestionType.DATE:
      return "DATE";
    case QuestionType.MEDICARE_NUMBER:
      return "MEDICARE_NUMBER";
    case QuestionType.PHOTO:
      return "PHOTO";
    case QuestionType.NPN:
      return "NPN";
    case QuestionType.WEBHOOK:
      return "WEBHOOK";
    case QuestionType.OPEN_LINK:
      return "OPEN_LINK";
    case QuestionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface QuestionnaireBranching {
  questionId: string;
  answers: { [key: string]: QuestionnaireBranching };
  /** map<string,string> answers = 2; */
  answersNo: { [key: string]: QuestionnaireBranching };
  /** optional  google.protobuf.Any  condition = 5; */
  genericQuestions: QuestionnaireBranching[];
}

export interface QuestionnaireBranching_AnswersEntry {
  key: string;
  value: QuestionnaireBranching | undefined;
}

export interface QuestionnaireBranching_AnswersNoEntry {
  key: string;
  value: QuestionnaireBranching | undefined;
}

export interface Questionnaire {
  id: string;
  questionMap: { [key: string]: Question };
  branching: { [key: string]: QuestionnaireBranching };
  name?: string | undefined;
}

export interface Questionnaire_QuestionMapEntry {
  key: string;
  value: Question | undefined;
}

export interface Questionnaire_BranchingEntry {
  key: string;
  value: QuestionnaireBranching | undefined;
}

export interface Question {
  id: string;
  type: QuestionType;
  title?: string | undefined;
  subTitle?: string | undefined;
  text?: string | undefined;
  description?: string | undefined;
  iconClass?: string | undefined;
  answers: QuestionAnswer[];
  style?: QuestionStyle | undefined;
  optional?: boolean | undefined;
  meta?: { [key: string]: any } | undefined;
  webhook?: WebHook | undefined;
}

export interface WebHook {
  url?: string | undefined;
  method?: number | undefined;
  body?: string | undefined;
  headers: HeaderContainer[];
  customActionLabel?: string | undefined;
  criterias: Criteria[];
}

export interface HeaderContainer {
  name?: string | undefined;
  value?: string | undefined;
}

export interface Criteria {
  id: string;
  field: string;
  operation: number;
  value: string;
  variable: string;
}

export interface AnswerContainer {
  answers: { [key: string]: Any };
  time: number;
}

export interface AnswerContainer_AnswersEntry {
  key: string;
  value: Any | undefined;
}

export interface AnswerContainer_Answer {
  id: string;
  active: boolean;
}

export interface QuestionAnswer {
  id: string;
  title: string;
  subTitle?: string | undefined;
  iconClass?: string | undefined;
}

export interface QuestionStyle {
  big: boolean;
}

function createBaseQuestionnaireBranching(): QuestionnaireBranching {
  return { questionId: "", answers: {}, answersNo: {}, genericQuestions: [] };
}

export const QuestionnaireBranching = {
  encode(message: QuestionnaireBranching, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.questionId !== "") {
      writer.uint32(10).string(message.questionId);
    }
    Object.entries(message.answers).forEach(([key, value]) => {
      QuestionnaireBranching_AnswersEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    Object.entries(message.answersNo).forEach(([key, value]) => {
      QuestionnaireBranching_AnswersNoEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    for (const v of message.genericQuestions) {
      QuestionnaireBranching.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuestionnaireBranching {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaireBranching();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.questionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = QuestionnaireBranching_AnswersEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.answers[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = QuestionnaireBranching_AnswersNoEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.answersNo[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.genericQuestions.push(QuestionnaireBranching.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuestionnaireBranching {
    return {
      questionId: isSet(object.questionId) ? String(object.questionId) : "",
      answers: isObject(object.answers)
        ? Object.entries(object.answers).reduce<{ [key: string]: QuestionnaireBranching }>((acc, [key, value]) => {
          acc[key] = QuestionnaireBranching.fromJSON(value);
          return acc;
        }, {})
        : {},
      answersNo: isObject(object.answersNo)
        ? Object.entries(object.answersNo).reduce<{ [key: string]: QuestionnaireBranching }>((acc, [key, value]) => {
          acc[key] = QuestionnaireBranching.fromJSON(value);
          return acc;
        }, {})
        : {},
      genericQuestions: Array.isArray(object?.genericQuestions)
        ? object.genericQuestions.map((e: any) => QuestionnaireBranching.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QuestionnaireBranching): unknown {
    const obj: any = {};
    message.questionId !== undefined && (obj.questionId = message.questionId);
    obj.answers = {};
    if (message.answers) {
      Object.entries(message.answers).forEach(([k, v]) => {
        obj.answers[k] = QuestionnaireBranching.toJSON(v);
      });
    }
    obj.answersNo = {};
    if (message.answersNo) {
      Object.entries(message.answersNo).forEach(([k, v]) => {
        obj.answersNo[k] = QuestionnaireBranching.toJSON(v);
      });
    }
    if (message.genericQuestions) {
      obj.genericQuestions = message.genericQuestions.map((e) => e ? QuestionnaireBranching.toJSON(e) : undefined);
    } else {
      obj.genericQuestions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionnaireBranching>, I>>(base?: I): QuestionnaireBranching {
    return QuestionnaireBranching.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionnaireBranching>, I>>(object: I): QuestionnaireBranching {
    const message = createBaseQuestionnaireBranching();
    message.questionId = object.questionId ?? "";
    message.answers = Object.entries(object.answers ?? {}).reduce<{ [key: string]: QuestionnaireBranching }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = QuestionnaireBranching.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.answersNo = Object.entries(object.answersNo ?? {}).reduce<{ [key: string]: QuestionnaireBranching }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = QuestionnaireBranching.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.genericQuestions = object.genericQuestions?.map((e) => QuestionnaireBranching.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuestionnaireBranching_AnswersEntry(): QuestionnaireBranching_AnswersEntry {
  return { key: "", value: undefined };
}

export const QuestionnaireBranching_AnswersEntry = {
  encode(message: QuestionnaireBranching_AnswersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      QuestionnaireBranching.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuestionnaireBranching_AnswersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaireBranching_AnswersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = QuestionnaireBranching.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuestionnaireBranching_AnswersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? QuestionnaireBranching.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: QuestionnaireBranching_AnswersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? QuestionnaireBranching.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionnaireBranching_AnswersEntry>, I>>(
    base?: I,
  ): QuestionnaireBranching_AnswersEntry {
    return QuestionnaireBranching_AnswersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionnaireBranching_AnswersEntry>, I>>(
    object: I,
  ): QuestionnaireBranching_AnswersEntry {
    const message = createBaseQuestionnaireBranching_AnswersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? QuestionnaireBranching.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuestionnaireBranching_AnswersNoEntry(): QuestionnaireBranching_AnswersNoEntry {
  return { key: "", value: undefined };
}

export const QuestionnaireBranching_AnswersNoEntry = {
  encode(message: QuestionnaireBranching_AnswersNoEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      QuestionnaireBranching.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuestionnaireBranching_AnswersNoEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaireBranching_AnswersNoEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = QuestionnaireBranching.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuestionnaireBranching_AnswersNoEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? QuestionnaireBranching.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: QuestionnaireBranching_AnswersNoEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? QuestionnaireBranching.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionnaireBranching_AnswersNoEntry>, I>>(
    base?: I,
  ): QuestionnaireBranching_AnswersNoEntry {
    return QuestionnaireBranching_AnswersNoEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionnaireBranching_AnswersNoEntry>, I>>(
    object: I,
  ): QuestionnaireBranching_AnswersNoEntry {
    const message = createBaseQuestionnaireBranching_AnswersNoEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? QuestionnaireBranching.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuestionnaire(): Questionnaire {
  return { id: "", questionMap: {}, branching: {}, name: undefined };
}

export const Questionnaire = {
  encode(message: Questionnaire, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    Object.entries(message.questionMap).forEach(([key, value]) => {
      Questionnaire_QuestionMapEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    Object.entries(message.branching).forEach(([key, value]) => {
      Questionnaire_BranchingEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Questionnaire {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaire();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = Questionnaire_QuestionMapEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.questionMap[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = Questionnaire_BranchingEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.branching[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Questionnaire {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      questionMap: isObject(object.questionMap)
        ? Object.entries(object.questionMap).reduce<{ [key: string]: Question }>((acc, [key, value]) => {
          acc[key] = Question.fromJSON(value);
          return acc;
        }, {})
        : {},
      branching: isObject(object.branching)
        ? Object.entries(object.branching).reduce<{ [key: string]: QuestionnaireBranching }>((acc, [key, value]) => {
          acc[key] = QuestionnaireBranching.fromJSON(value);
          return acc;
        }, {})
        : {},
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Questionnaire): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    obj.questionMap = {};
    if (message.questionMap) {
      Object.entries(message.questionMap).forEach(([k, v]) => {
        obj.questionMap[k] = Question.toJSON(v);
      });
    }
    obj.branching = {};
    if (message.branching) {
      Object.entries(message.branching).forEach(([k, v]) => {
        obj.branching[k] = QuestionnaireBranching.toJSON(v);
      });
    }
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Questionnaire>, I>>(base?: I): Questionnaire {
    return Questionnaire.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Questionnaire>, I>>(object: I): Questionnaire {
    const message = createBaseQuestionnaire();
    message.id = object.id ?? "";
    message.questionMap = Object.entries(object.questionMap ?? {}).reduce<{ [key: string]: Question }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Question.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.branching = Object.entries(object.branching ?? {}).reduce<{ [key: string]: QuestionnaireBranching }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = QuestionnaireBranching.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseQuestionnaire_QuestionMapEntry(): Questionnaire_QuestionMapEntry {
  return { key: "", value: undefined };
}

export const Questionnaire_QuestionMapEntry = {
  encode(message: Questionnaire_QuestionMapEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Question.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Questionnaire_QuestionMapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaire_QuestionMapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Question.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Questionnaire_QuestionMapEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Question.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Questionnaire_QuestionMapEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? Question.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Questionnaire_QuestionMapEntry>, I>>(base?: I): Questionnaire_QuestionMapEntry {
    return Questionnaire_QuestionMapEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Questionnaire_QuestionMapEntry>, I>>(
    object: I,
  ): Questionnaire_QuestionMapEntry {
    const message = createBaseQuestionnaire_QuestionMapEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Question.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuestionnaire_BranchingEntry(): Questionnaire_BranchingEntry {
  return { key: "", value: undefined };
}

export const Questionnaire_BranchingEntry = {
  encode(message: Questionnaire_BranchingEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      QuestionnaireBranching.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Questionnaire_BranchingEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionnaire_BranchingEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = QuestionnaireBranching.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Questionnaire_BranchingEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? QuestionnaireBranching.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Questionnaire_BranchingEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? QuestionnaireBranching.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Questionnaire_BranchingEntry>, I>>(base?: I): Questionnaire_BranchingEntry {
    return Questionnaire_BranchingEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Questionnaire_BranchingEntry>, I>>(object: I): Questionnaire_BranchingEntry {
    const message = createBaseQuestionnaire_BranchingEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? QuestionnaireBranching.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuestion(): Question {
  return {
    id: "",
    type: 0,
    title: undefined,
    subTitle: undefined,
    text: undefined,
    description: undefined,
    iconClass: undefined,
    answers: [],
    style: undefined,
    optional: undefined,
    meta: undefined,
    webhook: undefined,
  };
}

export const Question = {
  encode(message: Question, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.title !== undefined) {
      writer.uint32(26).string(message.title);
    }
    if (message.subTitle !== undefined) {
      writer.uint32(34).string(message.subTitle);
    }
    if (message.text !== undefined) {
      writer.uint32(42).string(message.text);
    }
    if (message.description !== undefined) {
      writer.uint32(50).string(message.description);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(58).string(message.iconClass);
    }
    for (const v of message.answers) {
      QuestionAnswer.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.style !== undefined) {
      QuestionStyle.encode(message.style, writer.uint32(74).fork()).ldelim();
    }
    if (message.optional !== undefined) {
      writer.uint32(80).bool(message.optional);
    }
    if (message.meta !== undefined) {
      Struct.encode(Struct.wrap(message.meta), writer.uint32(90).fork()).ldelim();
    }
    if (message.webhook !== undefined) {
      WebHook.encode(message.webhook, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Question {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.subTitle = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.text = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.answers.push(QuestionAnswer.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.style = QuestionStyle.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.optional = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.meta = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.webhook = WebHook.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Question {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      type: isSet(object.type) ? questionTypeFromJSON(object.type) : 0,
      title: isSet(object.title) ? String(object.title) : undefined,
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : undefined,
      text: isSet(object.text) ? String(object.text) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      answers: Array.isArray(object?.answers) ? object.answers.map((e: any) => QuestionAnswer.fromJSON(e)) : [],
      style: isSet(object.style) ? QuestionStyle.fromJSON(object.style) : undefined,
      optional: isSet(object.optional) ? Boolean(object.optional) : undefined,
      meta: isObject(object.meta) ? object.meta : undefined,
      webhook: isSet(object.webhook) ? WebHook.fromJSON(object.webhook) : undefined,
    };
  },

  toJSON(message: Question): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = questionTypeToJSON(message.type));
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.text !== undefined && (obj.text = message.text);
    message.description !== undefined && (obj.description = message.description);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e ? QuestionAnswer.toJSON(e) : undefined);
    } else {
      obj.answers = [];
    }
    message.style !== undefined && (obj.style = message.style ? QuestionStyle.toJSON(message.style) : undefined);
    message.optional !== undefined && (obj.optional = message.optional);
    message.meta !== undefined && (obj.meta = message.meta);
    message.webhook !== undefined && (obj.webhook = message.webhook ? WebHook.toJSON(message.webhook) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Question>, I>>(base?: I): Question {
    return Question.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Question>, I>>(object: I): Question {
    const message = createBaseQuestion();
    message.id = object.id ?? "";
    message.type = object.type ?? 0;
    message.title = object.title ?? undefined;
    message.subTitle = object.subTitle ?? undefined;
    message.text = object.text ?? undefined;
    message.description = object.description ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.answers = object.answers?.map((e) => QuestionAnswer.fromPartial(e)) || [];
    message.style = (object.style !== undefined && object.style !== null)
      ? QuestionStyle.fromPartial(object.style)
      : undefined;
    message.optional = object.optional ?? undefined;
    message.meta = object.meta ?? undefined;
    message.webhook = (object.webhook !== undefined && object.webhook !== null)
      ? WebHook.fromPartial(object.webhook)
      : undefined;
    return message;
  },
};

function createBaseWebHook(): WebHook {
  return {
    url: undefined,
    method: undefined,
    body: undefined,
    headers: [],
    customActionLabel: undefined,
    criterias: [],
  };
}

export const WebHook = {
  encode(message: WebHook, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== undefined) {
      writer.uint32(10).string(message.url);
    }
    if (message.method !== undefined) {
      writer.uint32(16).int32(message.method);
    }
    if (message.body !== undefined) {
      writer.uint32(26).string(message.body);
    }
    for (const v of message.headers) {
      HeaderContainer.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.customActionLabel !== undefined) {
      writer.uint32(42).string(message.customActionLabel);
    }
    for (const v of message.criterias) {
      Criteria.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebHook {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebHook();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.method = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.body = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.headers.push(HeaderContainer.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customActionLabel = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.criterias.push(Criteria.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebHook {
    return {
      url: isSet(object.url) ? String(object.url) : undefined,
      method: isSet(object.method) ? Number(object.method) : undefined,
      body: isSet(object.body) ? String(object.body) : undefined,
      headers: Array.isArray(object?.headers) ? object.headers.map((e: any) => HeaderContainer.fromJSON(e)) : [],
      customActionLabel: isSet(object.customActionLabel) ? String(object.customActionLabel) : undefined,
      criterias: Array.isArray(object?.criterias) ? object.criterias.map((e: any) => Criteria.fromJSON(e)) : [],
    };
  },

  toJSON(message: WebHook): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    message.method !== undefined && (obj.method = Math.round(message.method));
    message.body !== undefined && (obj.body = message.body);
    if (message.headers) {
      obj.headers = message.headers.map((e) => e ? HeaderContainer.toJSON(e) : undefined);
    } else {
      obj.headers = [];
    }
    message.customActionLabel !== undefined && (obj.customActionLabel = message.customActionLabel);
    if (message.criterias) {
      obj.criterias = message.criterias.map((e) => e ? Criteria.toJSON(e) : undefined);
    } else {
      obj.criterias = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WebHook>, I>>(base?: I): WebHook {
    return WebHook.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebHook>, I>>(object: I): WebHook {
    const message = createBaseWebHook();
    message.url = object.url ?? undefined;
    message.method = object.method ?? undefined;
    message.body = object.body ?? undefined;
    message.headers = object.headers?.map((e) => HeaderContainer.fromPartial(e)) || [];
    message.customActionLabel = object.customActionLabel ?? undefined;
    message.criterias = object.criterias?.map((e) => Criteria.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHeaderContainer(): HeaderContainer {
  return { name: undefined, value: undefined };
}

export const HeaderContainer = {
  encode(message: HeaderContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HeaderContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeaderContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HeaderContainer {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
    };
  },

  toJSON(message: HeaderContainer): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<HeaderContainer>, I>>(base?: I): HeaderContainer {
    return HeaderContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HeaderContainer>, I>>(object: I): HeaderContainer {
    const message = createBaseHeaderContainer();
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseCriteria(): Criteria {
  return { id: "", field: "", operation: 0, value: "", variable: "" };
}

export const Criteria = {
  encode(message: Criteria, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.field !== "") {
      writer.uint32(18).string(message.field);
    }
    if (message.operation !== 0) {
      writer.uint32(24).int32(message.operation);
    }
    if (message.value !== "") {
      writer.uint32(34).string(message.value);
    }
    if (message.variable !== "") {
      writer.uint32(42).string(message.variable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Criteria {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.field = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.operation = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.variable = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Criteria {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      field: isSet(object.field) ? String(object.field) : "",
      operation: isSet(object.operation) ? Number(object.operation) : 0,
      value: isSet(object.value) ? String(object.value) : "",
      variable: isSet(object.variable) ? String(object.variable) : "",
    };
  },

  toJSON(message: Criteria): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.field !== undefined && (obj.field = message.field);
    message.operation !== undefined && (obj.operation = Math.round(message.operation));
    message.value !== undefined && (obj.value = message.value);
    message.variable !== undefined && (obj.variable = message.variable);
    return obj;
  },

  create<I extends Exact<DeepPartial<Criteria>, I>>(base?: I): Criteria {
    return Criteria.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Criteria>, I>>(object: I): Criteria {
    const message = createBaseCriteria();
    message.id = object.id ?? "";
    message.field = object.field ?? "";
    message.operation = object.operation ?? 0;
    message.value = object.value ?? "";
    message.variable = object.variable ?? "";
    return message;
  },
};

function createBaseAnswerContainer(): AnswerContainer {
  return { answers: {}, time: 0 };
}

export const AnswerContainer = {
  encode(message: AnswerContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.answers).forEach(([key, value]) => {
      AnswerContainer_AnswersEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.time !== 0) {
      writer.uint32(16).int32(message.time);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnswerContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnswerContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = AnswerContainer_AnswersEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.answers[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.time = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnswerContainer {
    return {
      answers: isObject(object.answers)
        ? Object.entries(object.answers).reduce<{ [key: string]: Any }>((acc, [key, value]) => {
          acc[key] = Any.fromJSON(value);
          return acc;
        }, {})
        : {},
      time: isSet(object.time) ? Number(object.time) : 0,
    };
  },

  toJSON(message: AnswerContainer): unknown {
    const obj: any = {};
    obj.answers = {};
    if (message.answers) {
      Object.entries(message.answers).forEach(([k, v]) => {
        obj.answers[k] = Any.toJSON(v);
      });
    }
    message.time !== undefined && (obj.time = Math.round(message.time));
    return obj;
  },

  create<I extends Exact<DeepPartial<AnswerContainer>, I>>(base?: I): AnswerContainer {
    return AnswerContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnswerContainer>, I>>(object: I): AnswerContainer {
    const message = createBaseAnswerContainer();
    message.answers = Object.entries(object.answers ?? {}).reduce<{ [key: string]: Any }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Any.fromPartial(value);
      }
      return acc;
    }, {});
    message.time = object.time ?? 0;
    return message;
  },
};

function createBaseAnswerContainer_AnswersEntry(): AnswerContainer_AnswersEntry {
  return { key: "", value: undefined };
}

export const AnswerContainer_AnswersEntry = {
  encode(message: AnswerContainer_AnswersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Any.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnswerContainer_AnswersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnswerContainer_AnswersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Any.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnswerContainer_AnswersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Any.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AnswerContainer_AnswersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? Any.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AnswerContainer_AnswersEntry>, I>>(base?: I): AnswerContainer_AnswersEntry {
    return AnswerContainer_AnswersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnswerContainer_AnswersEntry>, I>>(object: I): AnswerContainer_AnswersEntry {
    const message = createBaseAnswerContainer_AnswersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Any.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseAnswerContainer_Answer(): AnswerContainer_Answer {
  return { id: "", active: false };
}

export const AnswerContainer_Answer = {
  encode(message: AnswerContainer_Answer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.active === true) {
      writer.uint32(16).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnswerContainer_Answer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnswerContainer_Answer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnswerContainer_Answer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      active: isSet(object.active) ? Boolean(object.active) : false,
    };
  },

  toJSON(message: AnswerContainer_Answer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.active !== undefined && (obj.active = message.active);
    return obj;
  },

  create<I extends Exact<DeepPartial<AnswerContainer_Answer>, I>>(base?: I): AnswerContainer_Answer {
    return AnswerContainer_Answer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnswerContainer_Answer>, I>>(object: I): AnswerContainer_Answer {
    const message = createBaseAnswerContainer_Answer();
    message.id = object.id ?? "";
    message.active = object.active ?? false;
    return message;
  },
};

function createBaseQuestionAnswer(): QuestionAnswer {
  return { id: "", title: "", subTitle: undefined, iconClass: undefined };
}

export const QuestionAnswer = {
  encode(message: QuestionAnswer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.subTitle !== undefined) {
      writer.uint32(26).string(message.subTitle);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(34).string(message.iconClass);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuestionAnswer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionAnswer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subTitle = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconClass = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuestionAnswer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
    };
  },

  toJSON(message: QuestionAnswer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionAnswer>, I>>(base?: I): QuestionAnswer {
    return QuestionAnswer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionAnswer>, I>>(object: I): QuestionAnswer {
    const message = createBaseQuestionAnswer();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.subTitle = object.subTitle ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    return message;
  },
};

function createBaseQuestionStyle(): QuestionStyle {
  return { big: false };
}

export const QuestionStyle = {
  encode(message: QuestionStyle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.big === true) {
      writer.uint32(8).bool(message.big);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuestionStyle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuestionStyle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.big = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuestionStyle {
    return { big: isSet(object.big) ? Boolean(object.big) : false };
  },

  toJSON(message: QuestionStyle): unknown {
    const obj: any = {};
    message.big !== undefined && (obj.big = message.big);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionStyle>, I>>(base?: I): QuestionStyle {
    return QuestionStyle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionStyle>, I>>(object: I): QuestionStyle {
    const message = createBaseQuestionStyle();
    message.big = object.big ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
