import { Injectable } from '@angular/core';
import { BaseUser } from '../../models';

interface Heap {
  track: (event: string, properties?: object) => void;

  identify: (identity: string) => void;

  resetIdentity: () => void;

  addUserProperties: (properties: unknown) => void;

  addEventProperties: (properties: object) => void;

  removeEventProperty: (property: string) => void;

  clearEventProperties: () => void;

  appid: string;

  userId: string;

  identity: string | null;
}

declare const heap: Heap;

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  private identifySent = false;

  constructor() {}

  identify(identity: string) {
    if (!heap) {
      setTimeout(() => {
        this.identify(identity);
      }, 1000);
    } else {
      heap.identify(identity);
      this.identifySent = true;
    }
  }

  addUserProperties(properties: BaseUser) {
    if (!heap || !this.identifySent) {
      setTimeout(() => {
        this.addUserProperties(properties);
      }, 1000);
    } else {
      const heapUser = BaseUser.toJSON(properties) as {
        [key: string]: unknown;
      };
      if (heapUser) {
        heapUser['Name'] =
          heapUser['name'] ??
          `${heapUser['firstName']} ${heapUser['lastName']}`;
        heap.addUserProperties(heapUser);
      }
    }
  }

  resetIdentity() {
    if (!heap) {
      setTimeout(() => {
        this.resetIdentity();
      }, 1000);
    } else {
      heap.resetIdentity();
      this.identifySent = false;
    }
  }
}
